<template>
  <div>
    <div>
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="名称：">
              <el-input v-model="search.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <br />
        <el-row>
          <el-button
            type="primary"
            @click="handleSearch"
          >查询</el-button>
          <el-button @click="clearSearch">清空</el-button>
        </el-row>
      </el-form>
    </div>
    <br />
    <div>
      <el-table
        :data="tableData"
        stripe
        border
        style="width: 100%"
      >
        <el-table-column
          prop="number"
          label="编号"
        ></el-table-column>
        <el-table-column
          prop="contentName"
          label="商品"
        ></el-table-column>
        <el-table-column
          prop="price"
          label="价格"
        ></el-table-column>
        <el-table-column
          prop="statusName"
          label="状态"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="用户"
        ></el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <img
              :src="scope.row.image"
              width="100px;"
              height="60px;"
              class="head_pic"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
        ></el-table-column>
        <!-- <el-table-column
          label="操作"
          width="200"
        >
          <template slot-scope="scope">
            <el-button
              @click="passClick(scope.row)"
              type="primary"
              size="small"
              v-if="scope.row.status === '0'"
            >通过</el-button>
            <el-button
              @click="rejectClick(scope.row)"
              type="danger"
              size="small"
              v-if="scope.row.status === '0'"
            >拒绝</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <br />
    <div style="text-align:middle;">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[]"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, slot, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { exportTempletes } from "../../../services/templete";
export default {
  data() {
    return {
      addVisible: false,
      modifyVisible: false,
      detailVisible: false,
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 5,
      },
      search: {
        name: "",
      },
      form: {
        uid: "",
        number: "",
        name: "",
        content: "",
        description: "",
        image: "",
      },
      dialogFormVisible: false,
      formLabelWidth: "120px",
      tableData: [],
    };
  },
  created() {
    this.list();
  },
  methods: {
    async passClick(row) {
      let param = {
        uid: row.uid,
        status: "1",
      };
      const result = await this.$http.post("/rest/forum/status", param);
      if (result.data.returnCode !== "200") {
        this.$message.error(result.data.message);
        return;
      }
      this.$message.success("操作成功");
      this.list();
    },
    async rejectClick(row) {
      let param = {
        uid: row.uid,
        status: "2",
      };
      const result = await this.$http.post("/rest/forum/status", param);
      if (result.data.returnCode !== "200") {
        this.$message.error(result.data.message);
        return;
      }
      this.$message.success("操作成功");
      this.list();
    },
    handleClick(row) {
      this.form = row;
      this.dialogFormVisible = true;
    },
    addClick() {
      // this.form = {};
      this.form.uid = "";
      this.addVisible = true;
    },
    modifyClick(row) {
      console.log("[modifyClick]row " + JSON.stringify(row));
      this.form = row;
      this.modifyVisible = true;
    },
    detailClick(row) {
      console.log("[detailClick]row " + JSON.stringify(row));
      this.form = row;
      this.detailVisible = true;
    },
    async modifyVaccinum() {
      let param = {
        name: this.form.name,
        content: this.form.content,
        description: this.form.description,
        image: this.form.image,
        uid: this.form.uid,
      };
      const result = await this.$http.post("/rest/notice/modify", param);
      if (result.data.returnCode !== "200") {
        this.$message.error(result.data.message);
        this.modifyVisible = false;
        return;
      }
      this.$message.success("保存成功！");
      this.modifyVisible = false;
      this.list();
    },
    async addVaccinum() {
      let param = {
        name: this.form.name.trim(),
        content: this.form.content,
        description: this.form.description,
        image: this.form.image,
      };
      const result = await this.$http.post("/rest/forum/add", param);
      if (result.data.returnCode !== "200") {
        this.$message.error(result.data.message);
        this.addVisible = false;
        return;
      }
      this.$message.success("保存成功！");
      this.addVisible = false;
      this.list();
    },
    async deleteClick(row) {
      this.form = row;
      let param = {
        uid: row.uid,
      };
      const result = await this.$http.post("/rest/forum/delete", param);
      if (result.data.returnCode !== "200") {
        this.$message.error(result.data.message);
        return;
      }
      this.$message.success("删除成功");
      this.list();
    },
    async list() {
      let param = {
        name: this.search.name,
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
      };
      const result = await this.$http.post("/rest/cart/list", param);
      if (result.data.returnCode !== "200") {
        this.$message.error(result.data.message);
        return;
      }
      this.tableData = result.data.returnData.list;
      this.page = result.data.returnData.page;
    },
    handleSearch() {
      this.list();
    },
    clearSearch() {
      this.search.name = "";
      this.list();
    },
    selectChange() {
      this.list();
    },
    handleCurrentChange(currentPage) {
      this.page.currentPage = currentPage;
      this.list();
    },
    /**
     * 设置分页的size
     */
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.currentPage = 0;
      this.list();
    },
    beforeUploadFile(file) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== "png" && extension !== "jpg" && extension !== "bmp") {
        this.$notify.warning({
          title: "警告",
          message: `只能上传后缀是png/jpg/bmp的图片`,
        });
      }
      if (size > 2) {
        this.$notify.warning({
          title: "警告",
          message: `文件大小不得超过2M`,
        });
      }
      this.uploadFile(file);
    },
    async uploadFile(file) {
      let param = new FormData();
      param.append("file", file);
      param.append("uid", this.form.uid);
      console.log("[uploadFile]uid " + this.form.uid);
      await this.$http
        .post("/rest/image/upload", param, {
          timeout: 10000,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.returnCode !== "200") {
            this.$message.error(res.data.message);
            return;
          }
          this.form.image = res.data.returnData.image;
          console.log("[uploadFile]image " + this.form.image);
        });
    },
    handleSuccess(file) {
      console.log("handleSuccess");
      console.log(file.name);
      this.$notify.success({
        title: "成功",
        message: `文件上传成功`,
      });
    },
    async exportClick() {
      let param = new URLSearchParams();
      await exportTempletes("/rest/user/export", param, "用户模板.xlsx");
    },
  },
};
</script>
<style scoped>
/* 解决 上传下载按钮 不能再一行显示 */
.inline-block {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}
</style>